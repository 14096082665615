import PropTypes from "prop-types"
import React from "react"

import Hero from "../components/Hero"
import Navbar from "./Navbar"

import Background from "../components/background"

const Header = ({ isStart }) => {
  return isStart ? (
    <header className="header">
      <Navbar isStart={isStart} />
      <Hero />
    </header>
  ) : (
    <>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: 140,
          margin: 0,
          padding: 0,
          left: 0,
          top: 0,
          overflow: "hidden",
        }}
      >
        <Background
          style={{
            width: "100%",
            height: "100vh",
            margin: 0,
            padding: 0,
            left: 0,
            top: 0,
            backgroundSize: "cover",
          }}
        />
      </div>
      <header
        className="header"
        style={{
          paddingTop: 120,
        }}
      >
        <Navbar isStart={isStart} />
      </header>
    </>
  )
}

Header.propTypes = {
  isStart: PropTypes.bool,
}

export default Header
