import React, { useState } from "react"
import PropTypes from "prop-types"

import { Link } from "gatsby"
import LogoSmall from "../components/logosmall"

const Navbar = ({ isStart }) => {
  /*
  let [transparent, setTransparent] = useState(1)
  const onScroll = () => {
    if (window.scrollY > window.innerHeight / 4) {
      setTransparent(0)
    } else {
      setTransparent(1)
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", onScroll)
    return
    window.removeEventListener("scroll", onScroll)
  }, [onScroll])
  */
  let [open, setOpen] = useState(false)

  return (
    <nav
      className="navbar"
      role="navigation"
      aria-label="main navigation"
      // style={isStart && { position: "absolute", top: 0, left: 0, right: 0 }}
      style={{
        position: "absolute",
        top: 10,
        left: 0,
        right: 0,
        backgroundColor: "transparent",
      }}
    >
      <div className="container" style={{ backgroundColor: "transparent" }}>
        <div className="navbar-brand">
          <a
            role="button"
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            onClick={() => setOpen(open ? false : true)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div
          id="navbarBasicExample"
          className={open ? "navbar-menu is-active" : "navbar-menu"}
        >
          <div
            className="navbar-start"
            style={
              isStart
                ? {}
                : {
                    position: "relative",
                    top: 70,
                  }
            }
          >
            {!isStart && (
              <LogoSmall
                style={{
                  position: "absolute",
                  top: -60,
                  left: 8,
                }}
              />
            )}
            <Link className="navbar-item" to="/haus/">
              <strong>Unser Haus</strong>
            </Link>
            <Link className="navbar-item" to="/apartments/">
              <strong>Die Apartments</strong>
            </Link>
            <Link className="navbar-item" to="/region/">
              <strong>Die Ferienregion</strong>
            </Link>
          </div>

          <div className="navbar-end">
            {/* <Link className="navbar-item" to="/info/"> */}
            {/*   <strong>A-Z für unsere Gäste</strong> */}
            {/* </Link> */}
            <div className="navbar-item">
              <div className="buttons">
                <a className="button is-warning">
                  <strong>Unverbindliche Anfrage</strong>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

Navbar.propTypes = {
  isStart: PropTypes.bool,
}

export default Navbar
