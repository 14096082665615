import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import Logo from "../components/logo"

/*
|--------------------------------------------------------------------------
| Hero
|--------------------------------------------------------------------------
*/

let { transform } = { transform: "scale(1)" }

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativeDirectory: { eq: "hero" } }
        sort: { order: ASC, fields: relativePath }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)

  let [rotate, setRotate] = useState(0)
  let [start, setStart] = useState(0)
  let [index, setIndex] = useState(0)

  useEffect(() => {
    setTimeout(setStart(1), 10)
    setTimeout(() => {
      setRotate(1)
    }, 6000)
    setInterval(() => {
      setIndex(index => (index + 1) % 4)
    }, 12000)
  }, [])

  return (
    <>
      <Image>
        {data.allFile.nodes.map((image, i) => {
          if ((rotate === 0 && i === 0) || rotate === 1) {
            if (start === 0) {
              transform = "scale(1)"
            } else if (index === i && start === 1) {
              transform = "scale(1.2)"
            } else if ((index + 3) % 4 === i) {
              transform = "scale(1.2)"
            } else {
              transform = "scale(1)"
            }

            return (
              <Img
                className="hero-image"
                key={i}
                fluid={image.childImageSharp.fluid}
                style={{
                  ...styles.image,
                  transform,
                  opacity: i === index ? 1 : 0,
                }}
              />
            )
          }
        })}
      </Image>
      <HeroContent>
        <Logo
          style={{
            width: 250,
          }}
        />
      </HeroContent>
    </>
  )
}

const HeroContent = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  top: 80px;
  height: 100vh;
`

const Image = styled.div`
  position: absolute;
  z-index: -9;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
`

const styles = {
  image: {
    position: "absolute",
    zIndex: -10,
    width: "100%",
    height: "100%",
    margin: 0,
    padding: 0,
    left: 0,
    top: 0,
    backgroundSize: "cover",
    transition: "opacity 4s, transform 14s linear",
  },
}

export default Hero
