import React from "react"

/*
|--------------------------------------------------------------------------
| Footer
|--------------------------------------------------------------------------
*/

const Footer = () => (
  <footer className="footer">
    <section>
      <div className="container">
        <div className="columns">
          <div className="column is-12">
            {/* test */}
            <div></div>
          </div>
        </div>
      </div>
    </section>
    <section
      style={{
        backgroundColor: "#fafafa",
      }}
    >
      <div className="container">
        <div className="columns">
          <div className="column is-7">
            <div className="title is-5">Wer wir sind</div>
            <div>
              Wir sind Privatvermieter in der Ferienregion Klopeiner See /
              Turnersee in Südkärnten. Wir vermieten seit über 25 Jahren unsere
              Ferienwohnungen an Familien aus ganz Europa.
            </div>
          </div>
          <div className="column is-5">
            <div className="title is-5">Kontakt</div>
            Haben Sie Interesse an unseren Wohnungen?
            <br />
            <button className="button is-info">Unverbindliche Anfrage</button>
            <br />
            <br />
            <br />
          </div>
        </div>
        <div className="columns">
          <div className="column is-12">
            <div className="has-text-centered" style={{ paddingBottom: 40 }}>
              <strong>kaernten-apartments.at</strong> ©{" "}
              {new Date().getFullYear()} Familie Jernej, Lauchenholzer Strasse
              9, 9122 St. Primus, Österreich
              <br />
              Telefon: +43 (0) 650 9981562 &nbsp; &nbsp; &nbsp; &nbsp; E-Mail:
              <a href="mailto:fewo@jerenj.com"> fewo@jernej.com</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </footer>
)

export default Footer
